<template>
    <router-view/>
</template>

<script setup lang="ts"></script>


<style>
#app{
  position: relative;
  height: 100vh;
  max-height: 100vh;
  overflow-y:scroll;
}


</style>
